import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { StyledContainer, Product, ProductContent } from "./styles"

SwiperCore.use([Navigation, Pagination])

const ProductsEstantes = () => {
  const data = useStaticQuery(graphql`
    query {
      estante5p25: file(
        relativePath: { eq: "products/estante-5-bandejas-p25.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      estante5p30: file(relativePath: { eq: "products/estante-5-p30.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      estante6p30: file(
        relativePath: { eq: "products/estante-6-bandejas.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      estante6p50: file(
        relativePath: { eq: "products/estante-6-bandejas-p50.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      estante3: file(
        relativePath: { eq: "products/estante-3-bandejas-p30.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledContainer as="section">
      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.estante6p30.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Estante de Aço padrão cinza</h3>

          <ul>
            <li>Com 6 bandejas</li>
            <li>Material em aço</li>
            <li>Pintura: eletrostática texturizada</li>
            <li>Peso: 20kg</li>
            <li>Altura: 198 cm </li>
            <li>Largura: 92 cm</li>
            <li>Profundidade: 30 cm</li>
            {/* <li>Chapa 26 R$ 365,00</li>
            <li>Chapa 24 R$ 450,00</li>
            <li>Chapa 22 R$ 530,00</li> */}
          </ul>

          {/* <p className="item-price">
            R$ 365,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.estante5p30.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Estante de Aço padrão cinza</h3>

          <ul>
            <li>Com 5 bandejas</li>
            <li>Material em aço</li>
            <li>Pintura: eletrostática texturizada</li>
            <li>Peso: 18kg</li>
            <li>Altura: 198 cm </li>
            <li>Largura: 92 cm</li>
            {/* <li>Profundidade: 30 cm</li>
            <li>Chapa 26 R$ 340,00</li>
            <li>Chapa 24 R$ 412,00</li>
            <li>Chapa 22 R$ 492,00</li> */}
          </ul>

          {/* <p className="item-price">
            R$ 340,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.estante6p30.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Estante de Aço padrão cinza</h3>

          <ul>
            <li>Com 6 bandejas</li>
            <li>Material em aço</li>
            <li>Pintura: eletrostática texturizada</li>
            <li>Peso: 18kg</li>
            <li>Altura: 198 cm </li>
            <li>Largura: 92 cm</li>
            <li>Profundidade: 42 cm</li>
            {/* <li>Chapa 26 R$ 397,00</li>
            <li>Chapa 24 R$ 477,00</li>
            <li>Chapa 22 R$ 557,00</li> */}
          </ul>

          {/* <p className="item-price">
            R$ 397,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.estante5p30.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Estante de Aço padrão cinza</h3>

          <ul>
            <li>Com 5 bandejas</li>
            <li>Material em aço</li>
            <li>Pintura: eletrostática texturizada</li>
            <li>Peso: 18kg</li>
            <li>Altura: 198 cm </li>
            <li>Largura: 92 cm</li>
            <li>Profundidade: 42 cm</li>
            {/* <li>Chapa 26 R$ 366,00</li>
            <li>Chapa 24 R$ 446,00</li>
            <li>Chapa 22 R$ 526,00</li> */}
          </ul>

          {/* <p className="item-price">
            R$ 366,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.estante6p50.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Estante de Aço padrão cinza</h3>

          <ul>
            <li>Com 6 bandejas</li>
            <li>Material em aço</li>
            <li>Pintura: eletrostática texturizada</li>
            <li>Peso: 25kg</li>
            <li>Altura: 198 cm </li>
            <li>Largura: 92 cm</li>
            <li>Profundidade: 50 cm</li>
            {/* <li>Chapa 26 R$ 436,00</li>
            <li>Chapa 24 R$ 516,00</li>
            <li>Chapa 22 R$ 594,00</li> */}
          </ul>

          {/* <p className="item-price">
            R$ 436,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.estante6p50.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Estante de Aço padrão cinza</h3>

          <ul>
            <li>Com 6 bandejas</li>
            <li>Material em aço</li>
            <li>Pintura: eletrostática texturizada</li>
            <li>Peso: 25kg</li>
            <li>Altura: 198 cm </li>
            <li>Largura: 92 cm</li>
            <li>Profundidade: 58 cm</li>
            {/* <li>Chapa 26 R$ 479,00</li>
            <li>Chapa 24 R$ 560,00</li>
            <li>Chapa 22 R$ 640,00</li> */}
          </ul>

          {/* <p className="item-price">
            R$ 479,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.estante5p25.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Estante de Aço padrão cinza</h3>

          <ul>
            <li>Com 5 bandejas sem reforços</li>
            <li>Material em aço</li>
            <li>Pintura: eletrostática texturizada</li>
            <li>Peso: 17kg</li>
            <li>Altura: 175 cm </li>
            <li>Largura: 75 cm</li>
            <li>Profundidade: 25 cm</li>
            {/* <li>Chapa 26 R$ 305,00</li> */}
          </ul>

          {/* <p className="item-price">
            R$ 305,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.estante3.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Estante de Aço padrão cinza</h3>

          <ul>
            <li>Com 3 bandejas sem reforços</li>
            <li>Material em aço</li>
            <li>Pintura: eletrostática texturizada</li>
            <br />
            <li>--- Cod.: E3 92x30</li>
            <li>Peso: 15kg</li>
            <li>Altura: 100 cm </li>
            <li>Largura: 92 cm</li>
            <li>Profundidade: 30 cm</li>
            {/* <li className="strong">Chapa 26 R$ 255,00</li> */}
            <br />
            <li>--- Cod.: E3 47x30</li>
            <li>Peso: 15kg</li>
            <li>Altura: 100 cm </li>
            <li>Largura: 47 cm</li>
            <li>Profundidade: 30 cm</li>
            {/* <li className="strong">Chapa 26 R$ 228,00</li> */}
            <br />
            <li>--- Cod.: E3 50x40</li>
            <li>Peso: 18kg</li>
            <li>Altura: 100 cm </li>
            <li>Largura: 50 cm</li>
            <li>Profundidade: 40 cm</li>
            {/* <li className="strong">Chapa 26 R$ 245,00</li> */}
          </ul>

          {/* <p className="item-price">
            R$ 228,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>
    </StyledContainer>
  )
}

export default ProductsEstantes
