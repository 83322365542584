import React from "react"

import SEO from "../components/seo"

import ProductsEstantes from "../components/products/products-estantes"

const EstanteAco = () => (
  <>
    <SEO title="Estantes de Aço" />

    <ProductsEstantes />
  </>
)

export default EstanteAco
